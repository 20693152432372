import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateConfigService {
language;

  constructor(
    private translate: TranslateService
  ) { }

  getDefaultLanguage(){
    return this.language;
    // let language = this.translate.getBrowserLang();
    // this.translate.setDefaultLang(language);
    // return language;
  }

  setLanguage($event) {
    this.translate.use($event.target.value);
    this.language =$event.target.value
    console.log($event.target.value)
  }

}