// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  googleWebClientId: '1006649866664-180sn5smd88esg84e53aklfe4qss7m36.apps.googleusercontent.com',
  firebase: {
    apiKey: "AIzaSyBrUq166ZTItXuL17Gmo3Po9bEicruDYOs",
    authDomain: "imcalhsa.firebaseapp.com",
    databaseURL: "https://imcalhsa.firebaseio.com",
    projectId: "imcalhsa",
    storageBucket: "imcalhsa.appspot.com",
    messagingSenderId: "1006649866664",
    appId: "1:1006649866664:web:cafcb348d2b36742",
    measurementId: "G-JXH9Z8VBR3"

  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
